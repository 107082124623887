import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import dayjs from 'dayjs';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import duration from 'dayjs/plugin/duration';

import './i18n/i18n';
import './index.css';
import './index.scss';
import './customStyles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/fonts/nasalization/nasalization.css';

import App from './app';

import store from './redux/store';
import { history } from './routers/history';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isBetween);
dayjs.extend(duration);

render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router history={history}>
          <App />
        </Router>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);
