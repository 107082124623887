// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.11.13 || YYYY/MM/DD

import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Col, Dropdown, Row, Space, Typography,
} from 'antd';
import Icon from '@mdi/react';
import { mdiMenuDown } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { capitalize } from 'lodash';

import './PublicInterface.scss';

import { AppstoreAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import InterfaceIcon54Origins from './InterfaceIcon54Origins';

import { getUrlAPI } from '../../../api/utils';
import { getCookie } from '../../utils54origins';

const { Text } = Typography;

function InterfacesListDropDown54Origins() {
  const { i18n: { language } = {} } = useTranslation();

  const [interfaces, setInterfaces] = useState([]);

  const items = useMemo(
    () => interfaces.map((item, key) => {
      const {
        service_name: name,
        service_domain: domain,
        icon_color: color,
        service_icon: icon,
      } = item;

      return {
        label: (
          <a href={domain} target="_blank" rel="noreferrer">
            <Space align="center">
              <InterfaceIcon54Origins
                color={color}
                iconName={icon}
                size={1.1}
              />
              <Row>
                <Col span={24}>
                  <span>{name}</span>
                </Col>
                <Col span={24}>
                  <Text type="secondary">{domain}</Text>
                </Col>
              </Row>
            </Space>
          </a>
        ),
        key: `service_${key}`,
        className: 'header-dropdown-item',
      };
    }),
    [interfaces],
  );

  const requestGetOpenInterfaces = () => {
    axios({
      method: 'get',
      url: `${getUrlAPI('entity')}/get_public_interfaces/`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getCookie('Entity'),
      },
    }).then(({ data }) => {
      setInterfaces(data?.services);
    });
  };

  useEffect(() => {
    requestGetOpenInterfaces();
  }, []);

  return (
    <div
      className="header-dropdown-wrapper"
      id="interfaces_list"
    >
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        placement="bottom"
        getPopupContainer={() => document.getElementById('interfaces_list')}
      >

        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: '5px',
          }}
        >
          <Avatar
            icon={
              <AppstoreAddOutlined style={{ color: '#1677ff' }} />
              }
            shape="square"
            style={{
              background: '#e6f4ff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <span style={{ fontSize: 15, fontWeight: 500 }}>
            {capitalize(language === 'en' ? 'services' : 'сервисы')}
          </span>
          <Icon path={mdiMenuDown} size={1} />
        </div>
      </Dropdown>
    </div>
  );
}

export default InterfacesListDropDown54Origins;
