import React, {
  useEffect, useMemo, useState,
} from 'react';
import { capitalize } from 'lodash';
import {
  Alert, Button, Card, Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { string } from 'prop-types';
import useAPI from '../../../../api/useAPI';

import { getProfileInfo } from '../../../../redux/profile/selectors';
import UserStatisticsForm from './UserStatisticsForm';
import { getMonthlyStorageInfo, getMonthlyStorageUUID } from '../../../../redux/storages/selectors';
import antNotification from '../../../../utils/antNotification';

export default function UserReportForLastMonth({ customActor }) {
  const myProfileData = useSelector(getProfileInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);

  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monthlyReport, setMonthlyReport] = useState(null);

  const {
    updateMonthlyReport, getOrCreateMonthlyReport,
  } = useAPI();

  const currentActor = customActor || myProfileData?.uuid;

  const lastMonthDate = dayjs().subtract(1, 'month').format('YYYY-MM');

  const initFunc = async () => {
    setLoading(true);
    const resReport = await getOrCreateMonthlyReport(
      monthlyStorageUUID,
      {
        actor: currentActor,
        params: {
          date: lastMonthDate,
          actorUuid: currentActor,
        },
      },
    );

    setMonthlyReport(resReport);
    setLoading(false);
  };

  const onFinish = async (values = {}, filesForReport = [], uuid = '', files = []) => {
    try {
      if (monthlyStorageUUID && currentActor) {
        const config = {
          entity_type: 'monthlyReport',
          entity_uuid: uuid || monthlyReport?.uuid,
          params: {
            status: 'submitted',
            statistics: {
              ...values,
              actorUuid: currentActor,
              created: dayjs().toString(),
              totalWorks: Math.ceil(values?.totalWorks) ?? 0,
              fullTotalWorks: values?.totalWorks ?? 0,
              totalDayOff: +(values?.sickday ?? 0) + +(values?.vacation ?? 0)
                + +(values?.dayoff ?? 0),
            },
            logs: monthlyReport?.params?.logs ?? [],
          },
        };

        if (monthlyReport?.params?.statistics) {
          let logFiles = [];
          if (monthlyReport?.params?.logs?.length) {
            logFiles = monthlyReport?.params?.logs?.reduce?.((acc, log) => {
              if (log?.files?.length) {
                return [...acc, ...(log?.files ?? [])];
              }
              return acc;
            }, []);
          }
          const newLogFiles = files
            ?.filter?.((file) => !logFiles?.includes(file?.uuid))
            .map((el) => el?.uuid);
          config.params.logs.unshift({
            ...(monthlyReport?.params?.statistics ?? {}),
            status: monthlyReport?.params?.status,
            files: newLogFiles,
          });
        }

        if (currentActor !== myProfileData.uuid) {
          config.actor = currentActor;
        }

        const newFormData = new FormData();

        newFormData.append('data', JSON.stringify(config));

        if (filesForReport.length !== 0) {
          for (let i = 0; i < filesForReport.length; i += 1) {
            newFormData.append('file', filesForReport[i]);
          }
        } else {
          newFormData.append('file', []);
        }

        await updateMonthlyReport(newFormData);
        antNotification.success('Отчет оправлен.');
        initFunc();
        setModalOpen(false);
      }
    } catch (e) {
      antNotification.error('Ошибка!');
      setModalOpen(false);
    }
  };

  const ReportAlertComponent = useMemo(() => {
    if (monthlyReport?.params?.status === 'submitted' && !loading) {
      return (
        <Alert
          // style={{ paddingInline: '16px', paddingBlock: '16px' }}
          message={(
            <>
              <div>
                Отчет по рабочим дням за прошлый месяц (
                {capitalize(dayjs(lastMonthDate).format('MMMM'))}
                ) отправлен!
              </div>
              <Button
                className="mt-3"
                type="primary"
                style={{ border: '#faad14', background: '#52c41a' }}
                onClick={() => setModalOpen(true)}
              >
                Скорректировать отчет за прошлый месяц (
                {capitalize(dayjs(lastMonthDate).format('MMMM'))}
                )
              </Button>
            </>
          )}
          type="success"
          showIcon
          description={(<div />)}
        />
      );
    }
    if (!loading) {
      return (
        <Alert
          // style={{ paddingInline: '16px', paddingBlock: '16px' }}
          message={(
            <>
              <div>
                Вы не отправили отчет за прошлый месяц (
                {capitalize(dayjs(lastMonthDate).format('MMMM'))}
                )!
                Пожалуйста, отправьте отчет!
              </div>
              <Button
                className="mt-3"
                type="primary"
                style={{ border: '#faad14', background: '#ffa500' }}
                onClick={() => setModalOpen(true)}
              >
                Отправить отчет за прошлый месяц (
                {capitalize(dayjs(lastMonthDate).format('MMMM'))}
                )
              </Button>
            </>
          )}
          type="warning"
          showIcon
          description={(<div />)}
        />
      );
    }
    return (
      <Spin
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        spinning
      />
    );
  }, [JSON.stringify(monthlyReport), loading]);

  useEffect(() => {
    if (currentActor && monthlyStorageUUID && currentActor === monthlyStorageInfo?.actor) {
      initFunc();
    }
  }, [currentActor, monthlyStorageUUID, isModalOpen]);

  return (
    <>
      {ReportAlertComponent}
      <UserStatisticsForm
        currentActor={currentActor}
        isModalOpen={isModalOpen}
        onCancelModal={() => setModalOpen(false)}
        currentMonth={lastMonthDate}
        onFinish={onFinish}
        formSettings={{
          title: `Отправка отчета за ${capitalize(dayjs(lastMonthDate).format('MMMM, YYYY'))}`,
          totalWorksLabel: capitalize('Отработанные часы: '),
          commentLabel: capitalize('Комментарий:'),
        }}
      />
    </>
  );
}

UserReportForLastMonth.propTypes = {
  customActor: string,
};
