import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';
import _ from 'lodash';
import { withCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { SERVICE_PARTITION_NAME } from '../api/app_config';
import antNotification from '../utils/antNotification';
import AuthHelper from '../utils/authUtils';

import useAPI from '../api/useAPI';

import {
  getConfigFetching,
  getEntityDomain,
  getHeaderPartition,
} from '../redux/config/selectors';
import { getFetchingHumanResources } from '../redux/humanResource/selectors';
import {
  getAccess,
  getProfileFetching,
} from '../redux/profile/selectors';
import { getPublicInterfacesFetching } from '../redux/publicInterfaces/selectors';
import useActorsAPI from '../api/useActorsApi';

function AppConfigProvider({ cookies, children }) {
  const {
    requestGetPublicInterfaces,
    requestGetPartitions,
  } = useAPI();

  const {
    requestGetProfile,
  } = useActorsAPI();

  const history = useHistory();

  const [everythingLoaded, setEverythingLoaded] = useState(false);

  const access = useSelector(getAccess);
  const entityDomain = useSelector(getEntityDomain);
  const headerPartitions = useSelector(getHeaderPartition);

  const isProfileFetching = useSelector(getProfileFetching);
  const isConfigFetching = useSelector(getConfigFetching);
  const isPublicInterfacesFetching = useSelector(getPublicInterfacesFetching);
  const isFetchingOwnHumanResource = useSelector(getFetchingHumanResources);

  const { pathname } = useLocation();

  if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
    localStorage.setItem('identifier', pathname.split('identifier=')[1]);
  }

  useEffect(async () => {
    if (entityDomain) {
      const partitions = await requestGetPartitions();

      const rootPartition = partitions.filter(
        (r) => r?.partition_name === 'delivery',
      );

      if (!rootPartition.length) {
        antNotification.error(
          `Root ${SERVICE_PARTITION_NAME} partition not found. Contact the administrator, please.`,
        );
      }
    }
  }, [entityDomain]);

  useEffect(() => {
    if (headerPartitions) {
      requestGetProfile().then((response) => AuthHelper.authorizedAccess(_.get(response, 'access', false)));
    }
  }, [headerPartitions]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const authToken = _.get(event, 'data.authToken');

      if (authToken) {
        cookies.set('Entity', authToken, { path: '/' });
        window.location.reload();
      }
    });

    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetPublicInterfaces();
    }
  }, [access]);

  useEffect(() => {
    if (AuthHelper.isAuthorised()) {
      if (pathname === '/') {
        history.push('/deliverydashboard');
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !isProfileFetching
      && !isConfigFetching
      && !isPublicInterfacesFetching
      && !isFetchingOwnHumanResource
    ) {
      setEverythingLoaded(true);
    }
  }, [
    isProfileFetching,
    isConfigFetching,
    isPublicInterfacesFetching,
    isFetchingOwnHumanResource,
  ]);

  if (!everythingLoaded) {
    return (
      <Spin
        size="large"
        spinning
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  return children;
}

export default compose(withRouter, withCookies)(AppConfigProvider);
