import React from 'react';

import {
  Button, Divider, Space, theme,
} from 'antd';
import { capitalize } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import AuthHelper from '../../utils/authUtils';
import ProfileMenu from './header/ProfileMenu';
import InterfacesListDropDown54Origins
  from '../../54origins/components/publicInterface54Origins/InterfacesListDropDown54Origins';
import StartWorkRoot from './startWorkRecord/StartWorkRoot';
import { getUserRootFetching, getUserRootInfo } from '../../redux/storages/selectors';

import './appHeader.scss';
import { getProfileInfo } from '../../redux/profile/selectors';
import InterfacesListModal54Origins from '../publicInterface54Origins/InterfacesListModal54Origins';

const headerStyle = {
  backgroundColor: '#ffffff',
  transition: 'all 0.2s ease',
  padding: '0',
  zIndex: 999,
  width: '100%',
  height: '50px',
  lineHeight: '50px',
};

const dividerStyle = { height: headerStyle.height };

export default function AppHeader({ isSidebarCollapsed, setSidebarCollapsed }) {
  const history = useHistory();
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isFetchingUser = useSelector(getUserRootFetching);
  const deliveryProfile = useSelector(getUserRootInfo);
  const myProfileData = useSelector(getProfileInfo);

  return AuthHelper.isAuthorised() ? (
    <Header
      style={{
        // display: 'flex',
        // alignItems: 'center',
        // background: colorBgContainer,
        // paddingLeft: 0,
        // paddingRight: 16,
        ...headerStyle,
      }}
    >
      <Space
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Space>
          <Button
            type="text"
            icon={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              width: 54,
              height: 54,
            }}
          />
          <Button
            icon={<QuestionOutlined />}
            type={location.pathname === '/help' ? 'primary' : 'default'}
            className="help-button"
            onClick={() => history.push('/help')}
          >
            {capitalize('help')}
          </Button>
        </Space>

        <Space
          align="top"
        >
          {(!isFetchingUser
            && deliveryProfile?.params?.showBaseReport
            && myProfileData?.uuid === deliveryProfile?.actor
          ) ? <StartWorkRoot /> : null}
          {/* <InterfacesListDropDown54Origins /> */}

          <InterfacesListModal54Origins />
          <Divider type="vertical" style={dividerStyle} />
          <ProfileMenu />
          <Divider type="vertical" style={dividerStyle} />
        </Space>
      </Space>
    </Header>
  ) : null;
}

AppHeader.propTypes = {
  isSidebarCollapsed: bool,
  setSidebarCollapsed: func,
};
