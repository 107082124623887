import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { omit } from 'lodash';

import { Col, Modal } from 'antd';
import useAPI from '../../../api/useAPI';

import StartWorkCard from './StartWorkCard';

import {
  getMonthlyStorageInfo, getMonthlyStorageUUID, getShowLunchBreak, getUserRootInfo,
} from '../../../redux/storages/selectors';
import { getProfileInfo } from '../../../redux/profile/selectors';
import { getFetchingMonthlyReport, getMonthlyReport } from '../../../redux/monthlyReport/selectors';

import MonthlyReportConstants from '../../../redux/monthlyReport/constants';
import { capitalizeFirstChar } from '../../../utils';
import antNotification from '../../../utils/antNotification';

import './startWorkRoot.scss';

export default function StartWorkRoot() {
  const {
    updateDayInMonthlyReport,
    getOrCreateMonthlyReport,
  } = useAPI();

  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyReportInfo = useSelector(getMonthlyStorageInfo);
  const myProfileData = useSelector(getProfileInfo);
  const monthlyReport = useSelector(getMonthlyReport);
  const isFetching = useSelector(getFetchingMonthlyReport);
  const showLunchBreak = useSelector(getShowLunchBreak);

  const [isModalOpen, setModalOpen] = useState(false);
  const [day, setDay] = useState(null);

  const actorUuid = myProfileData?.uuid;

  const month = dayjs().format('YYYY-MM');

  const handleCancel = () => {
    localStorage.setItem('dateOfShowingStartWorkModal', dayjs().format('YYYY-MM-DD'));
    setModalOpen(false);
  };

  const hasWorkRecord = useMemo(() => monthlyReport
    ?.params?.days?.some((el) => el?.rangeDates?.[0] === dayjs().format('YYYY-MM-DD')
    && el?.wasChoiceOfDay), [JSON.stringify(monthlyReport)]);

  const initFunc = async () => {
    const configRequest = {
      actor: actorUuid,
      params: {
        actorUuid,
        date: dayjs().format('YYYY-MM'),
      },
    };

    const constants = [
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_REQUEST,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_SUCCESS,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_FAILURE,
    ];

    await getOrCreateMonthlyReport(monthlyStorageUUID, configRequest, constants);
  };

  const updateDayCallback = async (data) => {
    try {
      const userDayOffs = [...(monthlyReport?.params?.days || [])];

      const constants = [
        MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_REQUEST,
        MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_SUCCESS,
        MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_FAILURE,
      ];

      const newDay = {
        ...data.timeState,
        comment: data?.dailyPlanningText ?? '',
        actorUuid,
        type: data?.kindOfDay,
        created: dayjs().toString(),
        rangeDates: [dayjs().format('YYYY-MM-DD')],
        wasChoiceOfDay: true,
        ...(data?.extraOptions ?? {}),
      };

      const oldDay = userDayOffs.find(({ rangeDates }) => rangeDates?.[0] === dayjs().format('YYYY-MM-DD'));

      if (oldDay) {
        newDay.logs = [{
          ...omit(oldDay, 'logs'),
        }, ...(oldDay?.logs ?? [])];
      }

      const newDays = [...userDayOffs].filter(({ rangeDates }) => rangeDates[0] !== dayjs().format('YYYY-MM-DD'));

      await updateDayInMonthlyReport(
        monthlyReport?.uuid,
        [...newDays, newDay],
        constants,
      );
      initFunc();
      antNotification.success('День успешно записан!');
    } catch (e) {
      antNotification.error('Ошибка!');
    }
    localStorage.setItem('dateOfShowingStartWorkModal', dayjs().format('YYYY-MM-DD'));
    setModalOpen(false);
  };

  const getDailyAndSetFirstEnterFromFocus = () => {
    const dateOfShowingStartWorkModal = localStorage.getItem('dateOfShowingStartWorkModal') || '';
    if (dayjs().format('YYYY-MM-DD') !== dateOfShowingStartWorkModal) {
      initFunc();
    }
  };

  const getPrevDayCallback = (currentDay = dayjs()) => {
    const sorteredDays = monthlyReport?.params?.days ?? [];

    if (!sorteredDays?.length) return null;

    return sorteredDays
      ?.sort((a, b) => dayjs(a?.rangeDates?.[0]).unix() - dayjs(b?.rangeDates?.[0]).unix())
      ?.reduce((acc, item) => {
        if (item?.hoursWorked && dayjs(item?.rangeDates?.[0]).isBefore(dayjs(currentDay))) {
          return item;
        }
        return acc;
      }, null) ?? null;
  };

  useEffect(() => {
    if (actorUuid && actorUuid === monthlyReportInfo?.actor && monthlyStorageUUID && month) {
      initFunc();

      window.addEventListener('focus', getDailyAndSetFirstEnterFromFocus);
    }
    return () => window.removeEventListener('focus', getDailyAndSetFirstEnterFromFocus);
  }, [actorUuid, monthlyStorageUUID, month]);

  useEffect(() => {
    if (!isFetching && monthlyReport?.uuid) {
      const days = monthlyReport?.params?.days ?? [];
      const currentDay = days?.find((el) => el?.rangeDates?.[0] === dayjs().format('YYYY-MM-DD')) ?? null;

      const dateOfShowingStartWorkModal = localStorage.getItem('dateOfShowingStartWorkModal') || '';

      setDay(currentDay);

      if (!currentDay?.wasChoiceOfDay && dateOfShowingStartWorkModal !== dayjs().format('YYYY-MM-DD')) {
        setModalOpen(true);
      } else {
        setModalOpen(false);
      }
    }
  }, [JSON.stringify(monthlyReport)]);

  return (
    <>
      {!isFetching ? (
        <div
          aria-hidden
          onClick={() => setModalOpen(true)}
          className={`button-open-start-work-record ${hasWorkRecord ? 'color-update-record' : 'color-create-record'}`}
        >
          {hasWorkRecord ? 'Изменить рабочий день' : 'Начать рабочий день'}
        </div>
      ) : null}
      <Modal
        title={capitalizeFirstChar(dayjs().format('dddd, DD MMMM YYYY'))}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={950}
        destroyOnClose
      >
        <div className="w-100">
          <StartWorkCard
            defaultDisabled={hasWorkRecord}
            showDeleteButton={false}
            showLunchBreak={showLunchBreak}
            defaultData={day}
            currentDay={dayjs().format('YYYY-MM-DD')}
            titleSaveBtn="Сохранить"
            updateDayCallback={updateDayCallback}
            getPrevDayCallback={getPrevDayCallback}
          />
        </div>
      </Modal>
    </>
  );
}
