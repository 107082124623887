import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { func, object, string } from 'prop-types';
import {
  Alert, Button, Flex, Input,
} from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';
import { concat, head } from 'lodash';
import { getProfileInfo } from '../../../../redux/profile/selectors';
import useAPI from '../../../../api/useAPI';
import { getVacationStorageUUID } from '../../../../redux/storages/selectors';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import antNotification from '../../../../utils/antNotification';

const { TextArea } = Input;

function PreAgreedVacationCard({ vacation, onUpdateVacation }) {
  const [comment, setComment] = useState('');

  const wasChecked = vacation?.params?.wasChecked;

  return (
    <Alert
      className="mb-3"
      key={vacation?.uuid}
      message={(
        <Flex
          vertical
        >
          <span
            className="text-lg"
          >
            Ваш отпуск в период с
            {' '}
            {dayjs(vacation?.params?.start_date).format('DD-MM-YYYY')}
            {' '}
            по
            {' '}
            {dayjs(vacation?.params?.end_date).format('DD-MM-YYYY')}
            {' '}
            предварительно согласован (согласовал:
            {' '}
            <b>{`${vacation?.params?.agreed?.uinfo?.first_name} ${vacation?.params?.agreed?.uinfo?.last_name}`.slice(0, 60)}</b>
            ).
          </span>
          {!wasChecked ? (
            <>
              <span>
                Уведомите об отпуске
                {' '}
                <b>вашего руководителя и коллег на проекте.</b>
              </span>
              <span
                className="text-lg"
              >
                После этого
                {' '}
                <b>необходимо</b>
                {' '}
                указать проекты и тех, кто был уведомлен на проекте (доступно только
                {' '}
                <b>1 раз</b>
                ).
              </span>
              <span
                className="mt-1 text-orange-400"
              >
                <span className="text-red-500 text-lg">*</span>
                Комментарий:
              </span>
              <TextArea
                className="w-1/2 border border-orange-200"
                value={comment}
                onChange={(e) => setComment(e?.target?.value)}
                maxLength={5000}
                rows={4}
              />
              <Button
                className={cn('w-[max-content] mt-2 border !border-orange-400 bg-orange-400 !text-white hover:!text-orange-400 hover:!bg-white', {
                  '!bg-orange-200': !comment?.length,
                  '!border-orange-200': !comment?.length,
                })}
                disabled={!comment?.length}
                onClick={() => onUpdateVacation(vacation?.uuid, comment)}
              >
                Дополнить отпуск
              </Button>
            </>
          ) : (
            <span
              className="text-lg"
            >
              Ожидайте утверждения отпуска.
            </span>
          )}
        </Flex>
    )}
      type={wasChecked ? 'info' : 'warning'}
      showIcon
      description={(<div />)}
    />
  );
}

PreAgreedVacationCard.propTypes = {
  vacation: object,
  onUpdateVacation: func,
};

export default function PreAgreedUserVacations({ customActor }) {
  const myProfileData = useSelector(getProfileInfo);
  const vacationStorageUUID = useSelector(getVacationStorageUUID);

  const {
    getUserVacationsRequests,
    requestUpdateUserVacation,
  } = useAPI();

  const [vacations, setVacations] = useState([]);
  const [approvedVacations, setApprovedVacations] = useState([]);

  const currentActor = customActor || myProfileData?.uuid;

  const getListPreAgreedVacationsRequests = async () => {
    const configRequest = {
      entity_type: 'vacation',
      params: {
        actorUuid: currentActor,
        status: 'pre-agreed',
      },
    };

    const res = await getUserVacationsRequests(
      vacationStorageUUID,
      configRequest,
      [
        'GET_PRE_AGREED_USER_VACATIONS_REQUEST',
        'GET_PRE_AGREED_USER_VACATIONS_SUCCESS',
        'GET_PRE_AGREED_USER_VACATIONS_FAILURE',
      ],
    );
    setVacations(res?.data ?? []);
  };

  const getAgreedVacationsRequests = async () => {
    const configRequest = {
      entity_type: 'vacation',
      params: {
        actorUuid: currentActor,
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs().format('YYYY-MM-DD'),
          dayjs().add(40, 'day').format('YYYY-MM-DD'),
        ),
      },
    };

    const res = await getUserVacationsRequests(
      vacationStorageUUID,
      configRequest,
      [
        'GET_APPROVED_USER_VACATIONS_REQUEST',
        'GET_APPROVED_USER_VACATIONS_SUCCESS',
        'GET_APPROVED_USER_VACATIONS_FAILURE',
      ],
    );
    setApprovedVacations(res?.data ?? []);
  };

  const onUpdateVacation = async (uuid, comment) => {
    if (uuid) {
      const res = await requestUpdateUserVacation(uuid, {
        additionalСomment: comment,
        wasChecked: true,
      });
      const changedVacation = head(res);
      if (changedVacation) {
        antNotification.success('Вы успешно дополнили отпуск!');
        setVacations((prev) => {
          const newVacations = prev?.filter((el) => el?.uuid !== changedVacation?.uuid);
          return concat(newVacations, res);
        });
      }
    }
  };

  useEffect(() => {
    if (vacationStorageUUID && currentActor) {
      getListPreAgreedVacationsRequests();
      getAgreedVacationsRequests();
    }
  }, [vacationStorageUUID, currentActor]);

  return (
    <>
      {approvedVacations.map((el) => (
        <Alert
          className="mb-3"
          key={el?.uuid}
          message={(
            <span
              className="text-lg"
            >
              Ваш отпуск в период с
              {' '}
              {dayjs(el?.params?.start_date).format('DD-MM-YYYY')}
              {' '}
              по
              {' '}
              {dayjs(el?.params?.end_date).format('DD-MM-YYYY')}
              {' '}
              утвержден (утвердил:
              {' '}
              <b>{`${el?.params?.reviewed?.uinfo?.first_name} ${el?.params?.reviewed?.uinfo?.last_name}`.slice(0, 60)}</b>
              ).
            </span>
          )}
          type="success"
          showIcon
          description={(<div />)}
        />
      ))}
      {vacations?.map((el) => (
        <PreAgreedVacationCard
          vacation={el}
          onUpdateVacation={onUpdateVacation}
        />
      ))}
    </>
  );
}

PreAgreedUserVacations.propTypes = {
  customActor: string,
};
