import React from 'react';

import { Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import UserVacationsPage from '../pages/admin/userVacations/UserVacationsPage';
import HelpPage from '../pages/user/HelpPage';
import ProfilePage from '../pages/user/Profile/ProfilePage';
import SettingsPage from '../pages/user/SettingsPage';
import AuthHelper from '../utils/authUtils';

import ErrorBoundary from '../components/ErrorBoundary';
import TimeDashboard from '../pages/user/TimeDashboard';
import ReportsPage from '../pages/user/ReportsPage';
import VacationPage from '../pages/user/VacationPage';
import UserDashboardRoot from '../pages/admin/userDashboard/UserDashboardRoot';

import UsersStatistics from '../pages/user/statistics/UsersStatistics';
import AdminStatistics from '../pages/admin/statistics/AdminStatistics';
import GitVersion54origins from '../54origins/components/publicInterface54Origins/GitVersion54origins';
import ShortLinksRedirect from '../components/ShortLinksRedirect';
import UserDashboardForAdmin from '../pages/admin/userDayDashboard/UserDashboardForAdmin';
import { getUserRootInfo } from '../redux/storages/selectors';

const checkApt = (component) =>
  // const isRootOrAdmin = useSelector(getUserRootInfo);
  // console.log(isRootOrAdmin);
  (
    <ErrorBoundary>
      {AuthHelper.isAuthorised() ? component : <Redirect to="/login" />}
    </ErrorBoundary>
  );
export const routes = [
  {
    path: '/profile',
    component: checkApt(<ProfilePage />),
    exact: true,
  },
  {
    path: '/reports',
    component: checkApt(<ReportsPage />),
    exact: true,
  },
  {
    path: '/vacations',
    component: checkApt(<VacationPage />),
    exact: true,
  },
  {
    path: '/settings',
    component: checkApt(<SettingsPage />),
    exact: true,
  },
  {
    path: '/help',
    component: checkApt(<HelpPage />),
    exact: true,
  },
  {
    path: '/deliverydashboard',
    component: checkApt(<TimeDashboard />),
    exact: true,
  },
  {
    path: '/statistics',
    component: checkApt(<UsersStatistics />),
    exact: true,
  },
  {
    path: '/version',
    component: checkApt(<GitVersion54origins />),
  },
  {
    path: '/s',
    component: checkApt(<ShortLinksRedirect />),
  },
];

export const adminRoutes = [
  {
    path: '/admin/vacations',
    component: checkApt(<UserVacationsPage />),
    exact: true,
  },
  {
    path: '/admin/statistics',
    component: checkApt(<AdminStatistics />),
    exact: true,
  },
  {
    path: '/userdashboard',
    component: checkApt(<UserDashboardRoot />),
  },
  {
    path: '/dayuserreport',
    component: checkApt(<UserDashboardForAdmin />),
  },
];
