import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
import {
  Alert, Card,
  Row, Space, Typography,
} from 'antd';

import { element, string } from 'prop-types';
import DayOffPage from './dayOff/DayOffPage';
import PageWrapper from '../../components/PageWrapper';
import RequestVacationsCard from './vacationsRequests/RequestVacationsCard';
import VacationInfo from '../admin/userVacations/VacationInfo';
import UserReportForLastMonth from './statistics/personal/UserReportForLastMonth';
import PreAgreedUserVacations from './vacationsRequests/components/PreAgreedUserVacations';

const { Paragraph } = Typography;

export default function TimeDashboard({ customTitle, actor }) {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const vacation = queryParameters.get('v');

  return (
    <>
      <PageWrapper
        title={customTitle || 'Дашборд'}
      >
        <Space direction="vertical" size="middle">
          <Card
            title="Мои задачи"
          >
            <PreAgreedUserVacations
              customActor={actor}
            />
            <UserReportForLastMonth
              customActor={actor}
            />
          </Card>
          <Card
            title="Месячная статистика"
          >
            <Alert
              style={{ paddingInline: '16px', paddingBlock: '16px' }}
              message="Общие правила по отправке отчетов"
              type="info"
              showIcon
              className="w-100"
              description={(
                <div>
                  <Paragraph style={{ marginBottom: 2 }}>
                    1. Отправка отчета допускается только за прошедшие месяцы.
                    Досрочная отправка отчетов крайне нежелательна.
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 2 }}>
                    2. Статистика вычисляется на основании данных с "Месячная статистика".
                  </Paragraph>
                </div>
              )}
            />
            <Row gutter={16} style={{ marginTop: 20 }}>
              <DayOffPage
                customActor={actor}
              />
            </Row>
          </Card>
          {/* <TimelineProjectsRoot customActor={actor} /> */}
          <RequestVacationsCard customActor={actor} />
        </Space>
      </PageWrapper>
      {vacation
        ? (
          <VacationInfo
            vacationUUID={vacation}
            showApprove={false}
          />
        )
        : null}
    </>
  );
}
TimeDashboard.propTypes = {
  customTitle: element,
  actor: string,
};
